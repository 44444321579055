<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 21">
    <defs>
      <clipPath id="a"><path d="M1440 0v1245H0V0h1440z" /></clipPath>
      <clipPath id="b">
        <path
          d="M10.065 0c5 0 10.064 1.332 10.064 3.871 0 2.54-5.063 3.87-10.064 3.87C5.063 7.742 0 6.412 0 3.872S5.063 0 10.065 0zm0 1.548c-5.513 0-8.517 1.549-8.517 2.323s3.004 2.323 8.517 2.323c5.512 0 8.516-1.549 8.516-2.323s-3.004-2.323-8.516-2.323z"
        />
      </clipPath>
      <clipPath id="c">
        <path
          d="M19.355 0c.427 0 .774.347.774.774V5.42c0 2.54-5.063 3.871-10.064 3.871C5.063 9.29 0 7.96 0 5.42V.773a.774.774 0 1 1 1.548 0c0 .774 3.004 2.323 8.517 2.323 5.512 0 8.516-1.549 8.516-2.323 0-.427.346-.774.774-.774zm-.774 2.934c-3.763 2.26-13.27 2.26-17.033 0V5.42c0 .775 3.004 2.323 8.517 2.323 5.512 0 8.516-1.548 8.516-2.323V2.934z"
        />
      </clipPath>
      <clipPath id="d"><path d="M1.548 0v4.645H0V0h1.548z" /></clipPath>
      <clipPath id="e"><path d="M1.548 0v4.645H0V0h1.548z" /></clipPath>
      <clipPath id="f">
        <path
          d="m5.218 0 .302 1.548c-2.74.504-3.972 1.41-3.972 1.882 0 .774 3.004 2.322 8.517 2.322 5.512 0 8.516-1.548 8.516-2.322a.705.705 0 0 0-.21-.395l1.13-1.053c.391.382.616.902.628 1.448 0 2.539-5.063 3.87-10.064 3.87C5.063 7.3 0 5.97 0 3.43 0 1.88 1.85.658 5.218 0z"
        />
      </clipPath>
      <clipPath id="g">
        <path
          d="M19.355 0c.427 0 .774.347.774.774V5.42c0 2.54-5.063 3.871-10.064 3.871C5.063 9.29 0 7.96 0 5.42V.773a.774.774 0 1 1 1.548 0c0 .774 3.004 2.323 8.517 2.323 5.512 0 8.516-1.549 8.516-2.323 0-.427.346-.774.774-.774zm-.774 2.934c-3.763 2.26-13.27 2.26-17.033 0V5.42c0 .775 3.004 2.323 8.517 2.323 5.512 0 8.516-1.548 8.516-2.323V2.934z"
        />
      </clipPath>
      <clipPath id="h"><path d="M1.548 0v4.645H0V0h1.548z" /></clipPath>
    </defs>
    <g clip-path="url(#a)" transform="translate(-425 -471)">
      <g clip-path="url(#b)" transform="translate(428.871 471)">
        <path fill="currentColor" d="M0 0h20.129v7.742H0V0z" />
      </g>
      <g clip-path="url(#c)" transform="translate(428.871 474.097)">
        <path fill="currentColor" d="M0 0h20.129v9.29H0V0z" />
      </g>
      <g clip-path="url(#d)" transform="translate(443.58 477.968)">
        <path fill="currentColor" d="M0 0h1.548v4.645H0V0z" />
      </g>
      <g clip-path="url(#e)" transform="translate(435.065 477.194)">
        <path fill="currentColor" d="M0 0h1.548v4.645H0V0z" />
      </g>
      <g clip-path="url(#f)" transform="translate(425 479.957)">
        <path fill="currentColor" d="M0 0h20.129v7.3H0V0z" />
      </g>
      <g clip-path="url(#g)" transform="translate(425 482.613)">
        <path fill="currentColor" d="M0 0h20.129v9.29H0V0z" />
      </g>
      <g clip-path="url(#h)" transform="translate(437.387 486.484)">
        <path fill="currentColor" d="M0 0h1.548v4.645H0V0z" />
      </g>
      <g clip-path="url(#h)" transform="translate(430.42 486.484)">
        <path fill="currentColor" d="M0 0h1.548v4.645H0V0z" />
      </g>
    </g>
  </svg>
</template>
